@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.storeCreditWrapper {
    display: grid;
    grid-row-gap: 0.5rem;
    grid-template-areas:
        'span'
        'button';

    @include responsive.media('>tablet') {
        grid-column-gap: 1.5rem;
        grid-template-areas: 'span button';
        grid-template-columns: 3fr 1fr;
    }
}

.appliedStoreCreditWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.useCreditButton {
    max-height: 2.5rem;
    min-width: 8rem;
    transition: none;
}

.availableStoreCredit {
    font-weight: fonts.$font-weight--bold;
}

.removeButton {
    @include mixins.link-underline($baseColor: colors.$text-color--red, $hasUnderline: true);
    align-items: center;
    color: var(--brand-color--primary, colors.$brand-color--primary);
    column-gap: 0.25rem;
    display: flex;
    white-space: nowrap;
}
