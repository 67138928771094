@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.signInContainer {
    width: 100%;

    .signInLabel svg {
        transform: rotate(90deg);
        transition: transform 0.16s ease-in-out;
    }

    @include responsive.media('>tablet') {
        background-color: colors.$color--white;
        border: 1px solid colors.$border-color--tertiary;
        border-radius: variables.$border-radius;
        margin-bottom: 0.5rem;
        padding: 1rem;
    }
}

.signInContainerOpened {
    composes: signInContainer;

    .signInLabel svg {
        transform: rotate(-90deg);
    }
}

.signInForm {
    @include mixins.form-grid();
}

.signInContainerContent {
    margin-top: 2rem;
}

.signInLabel {
    align-items: center;
    display: flex;
    font-weight: fonts.$font-weight--bold;
    justify-content: space-between;
    text-transform: capitalize;

    @include responsive.media('>tablet') {
        @include mixins.heading-3();
        text-transform: capitalize;
    }
}
