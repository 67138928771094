@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/z-index' as zIndex;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

.searchFieldWrapper {
    gap: 1rem;
}

.searchInputWrapper {
    position: relative;
}

.searchFieldWrapper,
.searchInputWrapper {
    align-items: center;
    display: flex;
    width: 100%;
}

.searchTrigger,
.returnTrigger {
    align-items: center;
    display: flex;
    justify-content: center;
}

.returnTrigger {
    color: colors.$color--white;
    stroke: colors.$color--white;
}

.clearButton,
.searchTrigger {
    position: absolute;
}

.suggestionSelected {
    background-color: colors.$border-color--primary;
    color: var(--brand-color--primary, colors.$brand-color--primary);

    .highlightClassName {
        color: var(--brand-color--primary, colors.$brand-color--primary);
    }

    .unHighlightClassName {
        text-shadow: 1px 0 0 var(--brand-color--primary, colors.$brand-color--primary);
    }
}

.searchTrigger {
    border-radius: 0 0.25rem 0.25rem 0;
    color: colors.$brand-color--secondary;
    left: 0.5rem;
    z-index: zIndex.$index--search-bar-search-icon;
}

.actionsWrapper {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.clearButton {
    color: colors.$color--white;
    right: 0.5rem;
    text-decoration: underline;
}

// White header design
@include whiteHeader.white-header-media {
            .searchTrigger {
                color: var(--color--gray-700);

            }

            .clearButton {
                color: var(--color--gray-700);
            }
}
