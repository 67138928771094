@use 'sass:math';
@use 'colors' as color;
@use 'breakpoints' as breakpoint;

/*
/*  General variables
*/

/*
/*  Box shadow
*/

$header--dropdown-box-shadow: 0 0.5rem 1rem -1rem rgba(color.$color--black, 50%);
$card--box-shadow: 0 0.3rem 0.5rem rgb(32 33 36 / 10%);
$material--box-shadow: 0 0.3rem 0.5rem rgb(0 0 0 / 16%), 0 0.3rem 0.4rem rgb(0 0 0 / 23%);
$swatch--box-shadow: 0 0 0 2px color.$color--white, 0.25rem 0.25rem 0.4rem rgb(0 0 0 / 20%);
$swatch--box-shadow-hover: 0 0 0 2px color.$color--white, 0.25rem 0.25rem 0.4rem rgb(0 0 0 / 45%);
$toast--box-shadow: 0 0.25rem 0.375rem -0.063rem rgba(0, 0, 0, 0.1), 0 0.125rem 0.25rem -0.063rem rgba(0, 0, 0, 0.06);

/*
/*  PLP Filters variables
*/

$transition-filter-background: background-color 0.16s ease-in-out;
$transition-filter-icon: transform 0.16s ease-in-out;

$background-color-filter: color.$color--wild-sand;

/*
/*  Modal variables
*/

$modal-padding: 1.5rem;
$modal-mask-background-color: rgba(0, 0, 0, 0.33);

/*
/*  Global gutters
*/

$mobile-page-gutter: 1rem;
$desktop-page-gutter: 1.5rem;

/*
/*  Screen dimensions
*/

$min-screen-width: breakpoint.$screen--xxs;
$max-screen-width: 112.5rem;
$max-search-component-width: math.div($max-screen-width, 2);
$min-modal-content-width: $min-screen-width - $modal-padding * 2;

/*
/*  Autocomplete
*/

$autocomplete-wrapper-padding: 1rem;

/*
/*  Border radius
*/

$tag-border-radius: 2px;
$border-radius: 0.25rem;

/*
/*  Animation helpers
*/
$header-height: 3.5rem;
$footer-height: 3.5rem;
