@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';
@use '@/styles/resources/responsive';

.masonryWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    > div {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;

        @include responsive.media('>=tablet') {
            width: calc(50% - 0.75rem);
        }

        @include responsive.media('>=desktop') {
            width: calc(33% - 1rem);
        }


        @include responsive.media('>=desktop-xl') {
            width: calc(25% - 1.125rem);
        }
    }
}

.addAddressIcon {
    stroke: colors.$text-color--primary;
}

:global(#hh) .addAnAddressAction {
    background-color: colors.$color--wild-sand;
    border: 2px dashed colors.$border-color--black;
    border-radius: variables.$border-radius;
    color: colors.$text-color--primary;
    font-size: 0.875rem;
    padding: 2rem;

    &:hover {
        background-color: colors.$color--white;
        border-color: colors.$border-color--black;
    }
}
