@use "sass:color";

/*
/*  Colors - http://chir.ag/projects/name-that-color/
*/

$color--gray: #c3c3c3;
$color--gray-lightest: #f2f2f2;
$color--gray-lighter: #e7e7e7;
$color--gray-light: #d6d6d6;
$color--gray-dark: #777070;
$color--gray-darker: #575353;
$color--gray-darkest: #282828;
$color--black: #000;
$color--mine-shaft: #222;
$color--emperor: #575353;
$color--dusty-gray: #979797;
$color--alto: #e0e0e0;
$color--mercury: #e5e5e5;
$color--wild-sand: #f6f6f6;
$color--white: #fff;
$color--zanah: #daedd7;
$color--oasis: #fdecd2;
$color--alizarin-crimson: #da2128;
$color--electric-red: #e60000;
$color--free-speech-red: #ac0000;
$color--fire: #b33e00;
$color--vida-loca: #4aa526;
$color--dell: #327119;
$color--cornflower-blue: #4871f7;
$color--orange-light: #f6ebe6;
$color--gallery: #efefef;

/*
/*  Brand Colors
*/

$brand-color--primary: $color--alizarin-crimson;
$brand-color--secondary: $color--white;
$brand-color--tertiary: $color--mine-shaft;
$brand-color--quaternary: $color--gray-lightest;

/*
/*  Buttons
*/

$button-background-color--primary: var(--brand-color--primary, $brand-color--primary);
$button-background-color--primary-hover: color.adjust($brand-color--primary, $lightness: 5%);
$button-background-color--primary-active: color.adjust($brand-color--primary, $lightness: 10%);

$button-background-color--secondary: $brand-color--secondary;
$button-background-color--secondary-hover: color.adjust($brand-color--secondary, $lightness: 5%);
$button-background-color--secondary-active: color.adjust($brand-color--secondary, $lightness: 10%);

$button-background-color--tertiary: $brand-color--tertiary;
$button-background-color--tertiary-hover: color.adjust($brand-color--tertiary, $lightness: 5%);
$button-background-color--tertiary-active: color.adjust($brand-color--tertiary, $lightness: 10%);

$button-background-color--quaternary: $brand-color--quaternary;
$button-background-color--quaternary-hover: color.adjust($brand-color--quaternary, $lightness: 5%);
$button-background-color--quaternary-active: color.adjust($brand-color--quaternary, $lightness: 10%);

$button-background-color--disabled: $color--mercury;

/*
/* Text
*/

$text-color--primary: $color--mine-shaft;
$text-color--secondary: $color--white;
$text-color--white: $color--white;
$text-color--light: $color--wild-sand;
$text-color--lighter: $color--gray-light;
$text-color--gray: $color--emperor;
$text-color--black: $color--black;
$text-color--green: $color--vida-loca;
$text-color--dark-green: $color--dell;
$text-color--red: $color--alizarin-crimson;

/*
/* Border
*/

$border-color--primary: $color--wild-sand;
$border-color--secondary: $color--dusty-gray;
$border-color--tertiary: $color--alto;
$border-color--light: $color--gray-lighter;
$border-color--gray: $color--gray-darker;
$border-color--dark: $color--mine-shaft;
$border-color--black: $color--emperor;

/*
/* Messages
*/

$message-background-color: $color--gray-lightest;
$message-background-color--error: $color--oasis;
$message-background-color--success: $color--zanah;

$message-text: $color--gray-darkest;
$message-text--error: $color--fire;
$message-text--success: $color--vida-loca;

$message-icon--error: $message-text--error;
$message-icon--success: $color--dell;
/*
/* Status
*/

$status-color--error: $color--fire;
$status-color--success: $color--dell;

/*
/* Progress Bar
*/

$progress-bar-background-color: $color--mercury;
$progress-bar-background-color--active: $color--mine-shaft;
$progress-bar-background-color--active-hover: $brand-color--primary;

/*
/* Notification
*/

$notification-background-color: $color--gray-lightest;
$notification-background-color--error: $color--oasis;
$notification-background-color--default: $color--gray-lightest;
$notification-background-color--success: $color--zanah;

$notification-icon-color--error: $color--fire;
$notification-icon-color--default: $color--gray-dark;
$notification-icon-color--success: $color--dell;

$notification-text-color: $color--gray-darkest;
$notification-text-color--error: $color--fire;
$notification-text-color--default: $color--gray-dark;
$notification-text-color--success: $color--dell;

/*
/* Radio buttons
*/

$radio-button-background-color--error: $color--orange-light;
$radio-button-background-color--success: $color--dell;

$radio-button-color--error: $color--fire;

/*
/* Tooltip
*/

$tooltip-background-color: $color--wild-sand;
$tooltip-text-color: $text-color--primary;
$tooltip-text-color--info: $color--cornflower-blue;
$tooltip-donation-text-color--info: $color--gray-dark;
