@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/fonts';

.root {
    padding-top: 1.125rem;
}

.title {
    font-weight: fonts.$font-weight--medium;
}

.mailingAddressTitle {
    font-weight: fonts.$font-weight--medium;
    padding-top: 1rem;
}

.note {
    padding: 1rem 0;
}

.formatAddress {
    padding: 0.5rem 0;
}

.addressLine {
    display: block;
    padding-bottom: 0.5rem;
}
