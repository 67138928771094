@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.root {
    /*
       * Each grid item gets at least 125px.
       * Wrap the grid items.
       */
    display: grid;
    gap: 1rem 3rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.radioLabel {
    composes: radioLabel from './radio.module.scss';
}
