@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.suggestionItem {
    list-style: none;

    &:only-child {
        grid-column: span 2;
    }
}

.suggestionsList {
    display: grid;

    &:empty {
        display: none;
    }
}

.suggestionsListHeading {
    composes: suggestionsListHeading from '../Suggestions/suggestions.module.scss';
}
