@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.container {
    margin: 0;
}

.linksList {
    align-items: center;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: none;

    @include responsive.media('>tablet', 'pointer-fine') {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
    }
}

.listItem {
    align-items: center;
    display: flex;
    margin-left: 2rem;
}

.itemLink {
    color: colors.$text-color--secondary;
    font-weight: fonts.$font-weight--medium;
    inline-size: max-content;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    text-align: left;

    &:hover {
        @include mixins.link-underline(colors.$text-color--secondary, colors.$text-color--secondary);
    }
}

.itemLinkLogOut {
    composes: itemLink;
    align-items: center;
    display: flex;
}

.divider {
    margin-left: 0;

    &:before {
        box-shadow: 1px 0 0 0 colors.$text-color--secondary;
        content: '';
        display: block;
        height: 0.625rem;
        margin: 0 0.5rem;
        width: 1px;
    }
}
