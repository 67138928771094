@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.prompt {
    @include mixins.heading-4();
    margin-bottom: 0.5rem;
}

.radiosWrapper {
    margin: 0.5rem 0;
}

.shippingContainerTrigger {
    display: block;
}

.shippingTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.shippingContainer {
    margin-top: 1.5rem;
    width: 100%;

    .shippingTitle svg {
        transform: rotate(90deg);
        transition: transform 0.16s ease-in-out;
    }
}

.shippingContainerOpened {
    composes: shippingContainer;

    .shippingTitle svg {
        transform: rotate(-90deg);
    }
}

.shippingContainerContent {
    border: 1px solid transparent;
}

.skeletonShippingRadios {
    display: grid;

    &Item {
        display: flex;
        gap: 0.2rem;
    }
}
