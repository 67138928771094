/*
/*  Font Families
*/

$font-family--base: 'Dinot Pro';
$font-family--sans-serif: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-family--base-global: $font-family--base, $font-family--sans-serif;

/*
/*  Font Weight
*/

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--medium: 500;
$font-weight--bold: 700;
$font-weight--black: 900;
