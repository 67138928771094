@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.root {
    display: grid;
    gap: 1.5rem;
    margin-top: 2rem;

    @include responsive.media('>tablet') {
        grid-template-columns: repeat(2, 1fr);
    }

    &Hidden {
        height: 0;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
    }

    > * {
        min-width: 0;

        @include responsive.media('>tablet') {
            grid-column: span 2;
        }
    }
}

.fieldSmall {
    @include responsive.media('>tablet') {
        grid-column: span 1;
    }
}

.formErrorContainer {
    padding-top: 1.5rem;
}

.shippingAddressWrapper {
    display: inline-block;
    font-size: 0.75rem;
    font-style: italic;
    margin-left: 1.75rem;
    opacity: 0.6;
    padding: 0.5rem;
}
