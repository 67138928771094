@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';
@use '@/styles/resources/mixins';

.root {
    font-size: 0.875rem;
}

.orderToolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include mixins.no-print
}

.orderHeading {
    font-size: 1.15rem;
    font-weight: fonts.$font-weight--medium;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.orderTableCaption {
    color: colors.$text-color--primary;
    font-size: 1rem;
    font-weight: fonts.$font-weight--bold;
    margin-bottom: 1rem;
    text-align: left;
    text-transform: uppercase;
}

.orderDate {
    color: colors.$text-color--gray;
    margin-bottom: 1.5rem;
}

.orderNumber {
    @include mixins.heading-2();
    font-weight: fonts.$font-weight--medium;
}

.orderStatus {
    color: colors.$text-color--gray;
    font-size: 1.1rem;
    text-transform: uppercase;
}

.orderTotal {
    background-color: colors.$color--wild-sand;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
}

.orderInfo {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}

.orderInfoTitle {
    font-size: 0.8rem;
    font-weight: fonts.$font-weight--medium;
    padding-bottom: 0.3rem;
}

.orderInfoContent {
    color: colors.$text-color--gray;
    text-transform: capitalize;
}

.button {
    align-items: center;
    color: colors.$text-color--gray;
    display: flex;
    font-size: 0.8rem;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.goBackButton {
    composes: button;
}

.goBackButtonIcon {
    height: 0.7rem;
    margin-right: 0.1rem;
    transform: scaleX(-1);
    width: 0.7rem;
}

.printButton {
    composes: button;
}

.printButtonIcon {
    height: 1.2rem;
    margin-right: 0.2rem;
    width: 1.2rem;
}

.orderTable {
    width: 100%;
}

.orderTableHead {
    display: none;
    font-size: 0.8rem;
    font-weight: fonts.$font-weight--bold;
    text-transform: uppercase;
}

.orderTableCell {
    padding: 0.3rem 0.5rem;
    text-align: left;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
        text-align: right;
    }
}

@include responsive.media('>tablet', 'pointer-fine') {
    .orderNumber {
        display: inline-block;
        margin-right: 1.3rem;
    }

    .orderInfo {
        grid-template-columns: repeat(3, 1fr);
    }

    .orderTableHead {
        display: table-header-group;
    }
}
