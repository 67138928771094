@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

//  Variables
//  ---------------------------------------------

$search-input-active-max-width: variables.$max-search-component-width;
$search-input-inactive-max-width: 19rem;

.root {
    align-items: center;
    border-radius: variables.$border-radius;
    justify-content: center;
    margin-left: auto;
    position: absolute;
    visibility: hidden;
    z-index: zIndex.$index--search-bar;

    @include responsive.media('>desktop', 'pointer-fine') {
        display: flex;
        max-width: $search-input-inactive-max-width;
        position: static;
        visibility: visible;
        width: 100%;
        z-index: zIndex.$index--search-bar-active;
    }

    &.wasSearchInteractedWith {
        animation: rollDownSearchInput 0.2s 0.2s both;
    }
}

.searchForm {
    align-items: center;
    background-color: var(--brand-color--primary, colors.$brand-color--primary);
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1rem 0;
    position: relative;
    width: 100%;
}

.rootActive {
    composes: root;
    animation: revealSearchInput 0.2s 0.2s both;
    display: flex;
    margin: auto;
    position: static;
    visibility: visible;
    width: 100%;
    z-index: zIndex.$index--search-bar-active;

    .searchForm {
        background-color: var(--brand-color--primary, colors.$brand-color--primary);
    }
}

.autocompleteWrapper {
    display: grid;
    z-index: zIndex.$index--search-autocomplete-wrapper;
}

.container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: zIndex.$index--search-bar-container;
}

.searchCloseTrigger {
    align-items: center;
    color: colors.$color--white;
    display: flex;
    justify-content: center;
    stroke: colors.$color--white;

    @include responsive.media('>tablet') {
        padding: 0 1rem;
    }
}

// White header design
@include whiteHeader.white-header-media {
            .searchForm {
                background: none;
                padding: 0.6875rem 0;

                .rootActive & {
                    background: none;
                }
            }

            .searchCloseTrigger {
                color: var(--color--gray-800, colors.$color--mine-shaft);
                stroke: var(--color--gray-800, colors.$color--mine-shaft);
            }
}

//  Search input specific animations
//  ---------------------------------------------
@keyframes revealSearchInput {
    0% {
        max-width: $search-input-inactive-max-width;
    }

    100% {
        max-width: $search-input-active-max-width;
    }
}

@keyframes rollDownSearchInput {
    0% {
        max-width: $search-input-active-max-width;
    }

    100% {
        max-width: $search-input-inactive-max-width;
    }
}
