@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';

.checkoutDiscountOptionContainer {
  width: 100%;

  .checkoutDiscountOptionLabel svg {
    transform: rotate(90deg);
    transition: transform 0.16s ease-in-out;
  }
}

.checkoutDiscountOptionContainerOpened {
  composes: checkoutDiscountOptionContainer;
  display: grid;
  row-gap: 1rem;

  .checkoutDiscountOptionLabel svg {
    transform: rotate(-90deg);
  }
}

.checkoutDiscountOptionLabel {
  align-items: center;
  border-bottom: 1px solid colors.$border-color--secondary;
  display: flex;
  font-size: 1rem;
  font-weight: fonts.$font-weight--medium;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
