@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;

.root {
    align-items: center;
    background-color: colors.$color--white;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top-style: none;
    box-shadow: variables.$header--dropdown-box-shadow;
    display: flex;
    flex-direction: column;
    font-size: 0.8125rem;
    gap: 1rem;
    left: -2rem;
    opacity: 0;
    overflow: hidden;
    padding: variables.$autocomplete-wrapper-padding;
    position: absolute;
    top: 100%;
    transition-property: transform, visibility, opacity;
    visibility: hidden;
    z-index: zIndex.$index--search-autocomplete;

    &:empty {
        padding: 0;
    }
}

.visible {
    opacity: 1;
    overflow: auto;
    pointer-events: unset;
    transform: translate3d(0, 0, 0);
    transition-delay: 0.2s;
    transition-duration: 224ms;
    transition-timing-function: var(--vaimo-animation-in);
    visibility: visible;

    &:not(:empty) {
        width: 100vw;

        @include responsive.media('>tablet') {
            left: unset;
        }

        @include responsive.media('>desktop', 'pointer-fine') {
            height: initial;
        }
    }
}

.hidden {
    pointer-events: none;

    @include responsive.media('>desktop', 'pointer-fine') {
        opacity: 0;
        position: absolute;
        transform: translate3d(0, -2rem, 0);
        transition-duration: 192ms;
        transition-timing-function: var(--vaimo-animation-out);
        visibility: hidden;
    }
}

.rootHidden {
    composes: root hidden;
}

.rootVisible {
    composes: root visible;
}

.lastSearches {
    display: grid;
    grid-gap: 0.3rem;
}

.sectionWrapper {
    border-top: 1px solid colors.$border-color--primary;
    padding: 0.5rem;
}

.message {
    font-weight: fonts.$font-weight--bold;
    text-align: center;
}
