@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.suggestionItem {
    display: flex;
    list-style: none;
}

.suggestionsList {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    @include responsive.media('>mobile') {
        grid-template-columns: repeat(4, 1fr);
    }
}

.suggestionsListHeading {
    composes: suggestionsListHeading from '../Suggestions/suggestions.module.scss';
}
