@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.fiscalCodeCollapsible,
.fiscalCodeCollapsibleContent {
    display: grid;
    gap: 1rem;
}

.fiscalCodeInfo {
    font-size: 0.75rem;
    margin: 0.5rem 0;
}

@include responsive.media('>tablet') {
    .fiscalCodeImage {
        max-width: 50%;
    }
}
