@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.benefitsItem {
    align-items: center;
    display: flex;
    gap: 0.75rem;

    @include vars.mq-medium-up {
        flex-direction: column;
        text-align: center;
    }

    &Text {
        flex: 1 1 0;
        @include vars.heading-7;
    }

    &Title {
        display: block;
        @include vars.heading-6;

        @include vars.mq-medium-up {
            padding-bottom: 0.25rem;
        }
    }
}
