@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'sass:color';

@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.radioLabel {
    align-items: center;
    cursor: pointer;
    display: grid;
    font-size: 0.875rem;
    gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
    padding: 0.4rem 1rem;

    input {
        height: 1.25rem;
        width: 1.25rem;

        .radio & {
            cursor: pointer;
            display: grid;
            outline: none;
            position: relative;

            & ~ label {
                cursor: pointer;
                padding-left: 0.5rem;
                width: 100%;
            }

            &::before {
                background-color: colors.$color--white;
                border: 1px solid color.adjust(colors.$border-color--gray, $lightness: 30%);
                border-radius: 100%;
                box-sizing: border-box;
                content: '';
                height: 1.25rem;
                margin-right: 1.25rem;
                position: absolute;
                transition: border-color 0.16s;
                visibility: visible;
                width: 1.25rem;
            }

            &:hover,
            &:focus-visible {
                &::before {
                    border: 1px solid colors.$border-color--gray;
                }
            }

            &:disabled {
                & ~ label {
                    opacity: 0.4;
                }

                &::before {
                    background-color: colors.$color--gray-lightest;
                    border-color: colors.$color--gray;
                    cursor: default;
                }
            }

            &::after {
                align-self: center;
                border-radius: 100%;
                content: '';
                height: 0.5rem;
                justify-self: center;
                position: absolute;
                visibility: hidden;
                width: 0.5rem;
            }

            &:focus-visible {
                border-color: colors.$border-color--gray;

                &::after {
                    background-color: color.adjust(colors.$text-color--primary, $lightness: 60%);
                    visibility: visible;
                }
            }

            &:checked::after {
                background-color: colors.$text-color--dark-green;
                visibility: visible;

                :global {
                    animation: tick 0.18s ease-in-out both;
                }
            }
        }
    }

    @include responsive.media('>tablet') {
        padding-bottom: 0.35rem;
        padding-top: 0.35rem;
    }
}

.fieldset {
    inset: -0.55rem 0 0;
    margin: 0;
    min-width: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-align: left;
}

.legend {
    display: block;
    float: unset;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    margin-left: 0.45rem;
    max-width: 100%;
    padding: 0;
    pointer-events: none;
    transform: translate3d(0, 0.1rem, 0);
    -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    user-select: none;
    visibility: hidden;
    width: auto;

    span {
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
