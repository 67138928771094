@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.suggestionsListHeading {
    font-size: 0.875rem;
    font-weight: fonts.$font-weight--bold;
    line-height: 1;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
}

.mainHeading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.suggestions {
    + .suggestions {
        border-top: 1px solid colors.$border-color--primary;
        margin-top: 1rem;
        padding-top: 1rem;
    }
}

.sectionWrapper {
    @include responsive.media('<=tablet') {
        width: 100%;
    }
}

.skeletonWrapper,
.suggestionsWrapper {
    column-gap: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    width: min(#{variables.$max-search-component-width}, 100%);
}

.skeletonSuggestions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &Section {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
    }

    &:last-of-type {
        width: 100%;
    }

    @include responsive.media('<=tablet') {
        width: 100%;
    }
}

.skeletonProducts {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    & > span {
        width: 100%;
    }
}
