@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
  @include mixins.form-grid();
}

.checkboxWrapper {
  display: grid;
  gap: 0.5rem;
}

@include responsive.media('>tablet', 'pointer-fine') {
  :global(#hh) .root {
    grid-template-columns: repeat(2, 1fr);

      > div {
          grid-column: -1/1;

      }
  }
}
