@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.radioGroupWrapper {
    cursor: pointer;
    gap: 0;
}

.radioWrapper {
    composes: radio from '@/components/RadioGroup/radio.module.scss';
    display: flex;
}

.radioLabel {
    composes: radioLabel from '@/components/RadioGroup/radio.module.scss';
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 30fr;
    padding: 0.25rem 0;

    svg {
        height: 1.3rem;
        width: 1.3rem;
    }
}
