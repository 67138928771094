@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/fonts';

.root {
    > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    [class='sizeguide-info__row'] {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
        justify-content: space-between;

        @include vars.mq-medium-up {
            flex-direction: row;
        }
    }

    h2 {
        @include vars.heading-2;

        font-weight: fonts.$font-weight--medium;
        margin: 1rem 0;
        text-transform: capitalize;
    }

    img {
        @include vars.mq-medium-up {
            max-width: 12.5rem;
        }
    }

    [class='sizeguide-info__desc'] {
        h5 {
            @include vars.heading-5;

            margin-bottom: 0.5rem;

            // This trick is needed as content seems to be coming from CMS in ALL CAPS
            text-transform: lowercase;

            &::first-letter,
            &::first-line {
                text-transform: capitalize;
            }
        }

        p {
            margin-bottom: 2rem;
        }
    }
}
