@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.couponCodeContainer {
    min-width: 18rem;
    width: 100%;

    .couponCodeLabel svg {
        transform: rotate(90deg);
        transition: transform 0.16s ease-in-out;
    }
}

.couponCodeContainerOpened {
    composes: couponCodeContainer;
    display: grid;
    row-gap: 1rem;

    .couponCodeLabel svg {
        transform: rotate(-90deg);
    }
}

.couponCodeLabel {
    align-items: center;
    border-bottom: 1px solid colors.$border-color--secondary;
    display: flex;
    font-size: 1rem;
    font-weight: fonts.$font-weight--medium;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}

.couponCodeWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.applyButton {
    max-height: 2.5rem;
}

.entryForm {
    display: grid;
    grid-row-gap: 0.5rem;
    grid-template-areas:
        'input'
        'error'
        'button';
    margin-inline: auto;
    padding-top: 1rem;

    input {
        grid-area: input;
    }

    button {
        grid-area: button;
    }

    p {
        border-bottom-left-radius: variables.$border-radius;
        border-bottom-right-radius: variables.$border-radius;
        color: colors.$message-text--error;
        font-size: 0.875rem;
        grid-area: error;
        padding: 0.5rem 0.75rem;
    }

    @include responsive.media('>tablet') {
        grid-column-gap: 1.5rem;
        grid-template-areas:
            'input button'
            'error error';
        grid-template-columns: 3fr 1fr;
    }
}

.removeButton {
    @include mixins.link-underline($baseColor: colors.$text-color--red, $hasUnderline: true);
    align-items: center;
    color: var(--brand-color--primary, colors.$brand-color--primary);
    column-gap: 0.25rem;
    display: flex;
    white-space: nowrap;
}
