@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/fonts';

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.unitsSelector {
    color: var(--color--gray-600);
    cursor: pointer;
    list-style: none;
    text-align: center;

    &Wrapper {
        display: flex;
        gap: 0.5rem;
    }
}

.selectedUnit {
    color: var(--color--black);
    text-decoration: underline;
}

.table {
    color: var(--color--gray-800);
    margin-bottom: 1rem;

    &Wrapper {
        overflow-x: auto;
        width: 100%;
    }
}

.rowHead {
    background-color: var(--color--gray-300);
}

.rowBody {
    border-bottom: 1px solid var(--color--gray-400);
}

.stickyColumn {
    left: 0;
    min-width: 13rem;
    padding: 0.75rem 1rem;
    position: sticky;
    text-align: left;

    @include vars.mq-medium-up {
        width: 25rem;
    }
}

.stickyColumnBody {
    composes: stickyColumn;
    background-color: var(--color--gray-200);
}

.stickyColumnHeader {
    composes: stickyColumn;
    background-color: var(--color--gray-300);
}

.cell {
    min-width: 5rem;
    padding: 0.75rem 1rem;
    text-align: center;
}

.cellHead {
    composes: cell;
}

.cellBody {
    composes: cell;
    border-right: 1px solid var(--color--gray-400);
}

.measurementUnit {
    font-weight: fonts.$font-weight--bold;
}
