@use 'sass:math';
@use 'colors' as color;
@use 'responsive';
@use 'z-index' as index;
@use 'variables';
@use 'fonts';

/*
/*  Mixins
*/

/*
/*  Links
*/

/* Usage:
/*
/* .selector{
/*    @include link-underline($textDecorationStyle: dashed); // please make it 'dashed' for internal use (popups, tooltips),
/*    @include link-underline(); // leave it empty to obtain a 'solid' line, (it's default)  - used for the links that navigate to another pages.
/* }
*/

@mixin link-underline(
    $baseColor: color.$brand-color--primary,
    $hoverColor: color.$brand-color--primary,
    $textDecorationStyle: solid,
    $cursor: pointer,
    $transitionTime: 0.2s,
    $hasUnderline: false
) {
    & {
        color: $baseColor;
        position: relative;

        @if $hasUnderline {
            text-decoration: underline;
            text-decoration-color: $hoverColor;
            text-decoration-style: $textDecorationStyle;
            text-decoration-thickness: 0.05rem;
            transition: text-decoration-color $transitionTime;
        }

        &:hover,
        &:focus-visible {
            cursor: $cursor;
            outline: none;
            text-decoration: underline;
            text-decoration-color: $hoverColor;

            @if $hasUnderline {
                text-decoration: none;
                text-decoration-color: transparent;
            }
        }
    }
}

@mixin link-shadow($baseColor: color.$text-color--primary, $hoverColor: color.$brand-color--primary, $cursor: pointer) {
    & {
        color: $baseColor;
        text-shadow: 1px 0 0 $baseColor;
        transition: text-shadow 0.16s ease-in-out;

        &:hover,
        &:focus-visible {
            color: $hoverColor;
            cursor: $cursor;
            text-shadow: 1px 0 0 $hoverColor;
        }
    }
}

@mixin new-design-link($hasUnderline: false) {
    position: relative;
    transition: color 0.2s ease-in;

    &::after {
        background-color: currentColor;
        bottom: -1px;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        width: 100%;

        @if $hasUnderline {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }

    &:hover {
        &::after {
            transform: scaleX(1);
            transform-origin: bottom left;

            @if $hasUnderline {
                transform: scaleX(0);
                transform-origin: bottom right;
            }
        }
    }
}

/*
/*  Animations
*/

@mixin small-loader($baseColor: color.$border-color--gray, $size: 2.5rem) {
    border: 0.3rem solid;
    border-color: $baseColor transparent;
    border-radius: 50%;
    height: $size;
    left: calc(50% - #{math.div($size, 2)});
    position: absolute;
    top: calc(50% - #{math.div($size, 2)});
    width: $size;

    :global {
        animation: spinner 1.2s linear infinite;
    }
}

/*
/*  Shadows
*/

@mixin vertical-scroll-shadow($baseColor: color.$color--white) {
    background:
        linear-gradient($baseColor 20%, rgba(9, 74, 142, 0)),
        linear-gradient(rgba(9, 74, 142, 0), $baseColor 80%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.07), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.07), rgba(0, 0, 0, 0)) 0 100%;
    background-attachment: local, local, scroll, scroll;
    background-color: $baseColor;
    background-repeat: no-repeat;
    background-size:
        100% 3rem,
        100% 3rem,
        100% 1.5rem,
        100% 1.5rem;
}

/*
/*  Overlays
*/

@mixin card-action-overlay {
    align-items: center;
    backdrop-filter: blur(1.5px);
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    height: 100%;
    justify-content: space-evenly;
    justify-items: center;
    left: 0;
    padding: 1.25rem 2rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: index.$index--overlay;
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        -webkit-backdrop-filter: blur(0.2rem);
        backdrop-filter: blur(0.2rem);
        background-color: rgba(255, 255, 255, 0.8);
    }
}

@mixin card {
    background-color: color.$color--white;
    border: 1px solid color.$border-color--tertiary;
    border-radius: 0.25rem;
    display: grid;
    font-size: 0.9rem;
    gap: 1rem;
    hyphens: auto;
    padding: 1rem;
    position: relative;
    word-break: break-word;
}

@mixin shimmer($baseColor: color.$color--white, $position: relative) {
    overflow: hidden;
    position: $position;

    &::after {
        animation: shimmer 1.6s infinite;
        background-image: linear-gradient(
            90deg,
            rgba($baseColor, 0) 0,
            rgba($baseColor, 0.2) 20%,
            rgba($baseColor, 0.5) 60%,
            rgba($baseColor, 0)
        );
        bottom: 0;
        content: '';
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

@mixin swatch-border-overlay {
    position: relative;

    &::after {
        border: 1px solid color.$color--black;
        border-radius: 50%;
        content: '';
        display: block;
        filter: contrast(0.8);
        height: inherit;
        mix-blend-mode: difference;
        position: absolute;
        width: inherit;
    }
}

/*
/*  Filters
*/

@mixin dropdown-filter($isFromLeft: false) {
    background-color: color.$color--white;
    display: none;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 1rem 0;
    width: 100%;
    z-index: index.$index--modal;

    input[type='checkbox'] ~ label {
        padding: 0.4rem 1rem;
    }

    @include media('>mobile') {
        max-height: 20rem;
    }

    @include media('>tablet') {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top: 0;
        box-shadow: variables.$card--box-shadow;
        left: 0;
        margin-left: -1px;
        position: absolute;
        top: 100%;
        width: max-content;

        input[type='checkbox'] ~ label {
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;

            &:hover {
                background-color: color.$border-color--primary;
            }
        }

        @if $isFromLeft {
            left: auto;
            right: 0;
        }
    }
}

@mixin button-filter($shouldRotate: true) {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    width: 100%;

    &Active {
        background-color: variables.$background-color-filter;
    }

    // Background changes, outline can be disabled here
    &:focus {
        outline: none;
    }

    @if $shouldRotate {
        > svg {
            color: color.$text-color--primary;
            display: flex;
            margin-left: auto;
            transform: rotate(90deg);
            transition: variables.$transition-filter-icon;
        }
    }

    @include responsive.media('>tablet') {
        transition: variables.$transition-filter-background;
        width: max-content;

        &:hover,
        &:focus-within,
        &:focus,
        &:active {
            background-color: variables.$background-color-filter;
        }
    }
}

/*
/*  Forms
*/

@mixin form-grid {
    display: grid;
    gap: 1.5rem;

    @include responsive.media('>tablet') {
        grid-template-columns: repeat(2, 1fr);
    }

    > * {
        min-width: 0;

        @include responsive.media('>tablet') {
            grid-column: span 2;
        }
    }

    div:empty {
        display: none;
    }
}

/*
/*  Buttons
*/

@mixin trigger-icon {
    background-color: color.$color--gray-lightest;
    border-radius: 0.75rem;
    display: grid;
    outline: none;
    overflow: hidden;
    padding: 0.75rem;

    &:active,
    &:focus,
    &:focus-visible,
    &:hover {
        outline: none;
    }

    @include responsive.media('>tablet', 'pointer-fine') {
        background-color: transparent;

        &:active,
        &:focus,
        &:focus-visible,
        &:hover {
            background-color: color.$color--gray-lightest;
        }
    }

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}

@mixin trigger-icon-no-background {
    @include trigger-icon();

    background: none;

    @include responsive.media('>tablet', 'pointer-fine') {
        background-color: none;

        &:active,
        &:focus,
        &:focus-visible,
        &:hover {
            background: none;
        }
    }
}

/*
/*  Headings
*/

@mixin heading {
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

@mixin heading-1 {
    @include heading;
    font-size: 1.875rem;
}

@mixin heading-2 {
    @include heading;
    font-size: 1.5rem;
}

@mixin heading-3 {
    @include heading;
    font-size: 1.25rem;
}

@mixin heading-4 {
    @include heading;
    font-size: 0.75rem;
}

@mixin heading-5 {
    @include heading-4;
}

@mixin heading-6 {
    @include heading;
    font-size: 0.625rem;
}

@mixin checkoutStepTitle {
    @include heading-2;
    align-items: center;
    display: flex;
    gap: 1rem;
    text-transform: capitalize;
}

@mixin checkoutStepTitleAsTooltipTrigger {
    @include checkoutStepTitle;
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;

    h1,
    h2,
    h3 {
        @include heading-2;
        text-transform: capitalize;
    }

    > svg {
        margin-left: auto;
        stroke: color.$tooltip-text-color--info;
    }
}

@mixin checkoutStepSubtitle {
    align-items: center;
    display: flex;
    font-size: 1.25rem;
    font-weight: fonts.$font-weight--regular;
    gap: 1rem;
    text-transform: capitalize;
}

@mixin donationProductTooltipTitle {
    @include heading-4;
    align-items: center;
    display: flex;
    font-size: 0.75rem;
    font-weight: fonts.$font-weight--bold;
    gap: 0.75rem;
    text-transform: uppercase;
    width: 100%;

    > svg {
        margin-left: auto;
        stroke: color.$tooltip-donation-text-color--info;
    }
}

@mixin punchTextShadow {
    @include heading-2;
    color: color.$text-color--secondary;
    text-shadow: 1px 1px 3px color.$text-color--gray;
}

/*
/*  Accessibility
*/

@mixin a11y-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}

/*
/*  Print
*/

@mixin no-print {
    @include responsive.media('print') {
        display: none;
    }
}

/*
/*  Icons
*/

@mixin success-icon {
    color: color.$text-color--green;
}
