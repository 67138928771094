@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    background-color: colors.$brand-color--tertiary;
    color: colors.$text-color--secondary;
    font-size: 0.75rem;
    font-weight: fonts.$font-weight--medium;
    min-height: 2.125rem;
    padding: 0.5rem 0;
    text-align: center;

    a {
        @include mixins.link-underline(
            colors.$text-color--secondary,
            colors.$text-color--secondary,
            $hasUnderline: true
        );
    }

    li {
        display: inline-block;
    }
}

.animatedPreHeader {
    animation: header-fade 9.4s ease-in-out infinite;
}

.isTextCentered {
    text-align: center !important;
}

@include responsive.media('>tablet', 'pointer-fine') {
    .root {
        padding-left: 0;
        padding-right: 0;
        text-transform: none;
    }
}

@include responsive.media('>desktop', 'pointer-fine') {
    .root {
        margin: 0;
        text-align: left;
    }
}

@keyframes header-fade {
    0% {
        opacity: 0;
    }

    13% {
        opacity: 1;
    }

    87% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
