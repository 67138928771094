@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndexes;

.modal {
    align-content: center;
    height: 100%;
    left: -100vw;
    opacity: 0;
    position: fixed;
    top: 0;
    transform: translate3d(
        -50%,
        0,
        0
    ); // If you find yourself in a situation to try and fix blurred out fonts, or borders, don't even bother, you will not win, but translate is the culprit.
    visibility: hidden;
    width: 100%;
    z-index: zIndexes.$index--modal;
}

.modalActive {
    composes: modal;
    display: grid;
    left: 50%;
    opacity: 1;
    place-items: center;
    visibility: visible;
}

.dialog {
    background-color: colors.$color--white;
    box-shadow: variables.$card--box-shadow;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 1.5rem;
    place-content: flex-start;
    width: 100%;
    z-index: zIndexes.$index--modal-content;

    @include responsive.media('>tablet') {
        border-radius: variables.$border-radius;
        max-height: 90vh;
        place-content: stretch;
        width: min(46rem, 100%);
    }
}

.header {
    align-items: center;
    border-bottom: 1px solid colors.$border-color--tertiary;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
}

.title {
    font-size: 0.9em;
    font-weight: fonts.$font-weight--bold;
    text-transform: uppercase;

    @include responsive.media('>tablet') {
        font-size: 1.2em;
    }
}

.productTitle {
    composes: title;
    text-transform: none;
}

.closeIcon {
    color: colors.$text-color--primary;
}

.closeButton {
    @include mixins.trigger-icon();
    margin-left: auto;
}

.content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-top: 1rem;
    overflow: auto;
    padding-top: 1rem;
    scroll-behavior: smooth;
}
