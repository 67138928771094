@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.radioGroupWrapper {
    @include mixins.card();
    cursor: pointer;
    gap: 0;
    padding: 0 0 0 1rem;
}

.radioWrapper {
    composes: radio from '@/components/RadioGroup/radio.module.scss';
    display: flex;
}

.radioLabel {
    composes: radioLabel from '@/components/RadioGroup/radio.module.scss';
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 30fr;
    padding: 1rem 1rem 1rem 0.5rem;

    svg {
        height: 1.3rem;
        width: 1.3rem;
    }
}

:global(#hh) {
    .radioLabel {
        padding: 1rem 1rem 1rem 0.5rem;
    }
}
