@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/mixins';

.root {
    @include mixins.no-print
}

:global(#hh) {
    .footerSection {
        padding-top: 2.5rem;

        @include vars.mq-medium-up {
            padding-bottom: 2.5rem;

            + .footerSection {
                border-top: 1px solid var(--color--gray-400);
            }
        }
    }

    .footerGrid {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(6.25rem, 1fr));

        @include vars.mq-small {
            display: none;
        }
    }

    .footerLinksAccordion {
        @include vars.mq-medium-up {
            display: none;
        }
    }

    .footerLinksWrapper {
        display: grid;
        gap: 1.5rem;

        @include vars.mq-medium-up {
            gap: 1rem;
            grid-template-columns: repeat(auto-fit, minmax(6.25rem, 1fr));
        }
    }

    .footerLinks {
        li {
            padding: 0.5rem 0;

            @include vars.mq-medium-up {
                padding-bottom: 0;
            }
        }
    }

    .footerBlockWrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-bottom: 2.5rem;

        @include vars.mq-medium-up {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .footerBlock {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        text-align: center;

        @include vars.mq-medium-up {
            align-items: center;
            flex-direction: row;

            + .footerBlock {
                justify-content: flex-end;
            }
        }

        &List {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            justify-content: center;

            @include vars.mq-medium-up {
                justify-content: flex-start;
            }
        }
    }

    .footerSocialList {
        gap: 1rem;
    }

    .footerBottomWrapper {
        background-color: var(--color--black);
        color: var(--color--white);
        display: flex;
        flex-direction: column;
        font-size: vars.$font-size--sm;
        gap: 1.5rem;
        padding: 1.5rem 0 4.5rem;
        text-align: center;

        @include vars.mq-large-up {
            padding: 2rem 0;
        }
    }

    .footerCountry {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        margin-top: 1.5rem;
        text-align: center;

        &Hide {
            display: none;
        }

        @include vars.mq-medium-up {
            flex-direction: row;
        }

        &Text {
            align-items: center;
            display: flex;
            gap: 0.5rem;
            justify-content: center;
        }
    }

    .footerFlag {
        cursor: pointer;
    }

    .footerNavigation {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        justify-content: center;

        @include vars.mq-medium-up {
            align-items: center;
            flex-direction: row;
            gap: 0.8rem;

            li {
                align-items: center;
                display: inline-flex;

                + li {
                    &::before {
                        background-color: var(--color--white);
                        content: '';
                        display: block;
                        height: 1.25rem;
                        margin-right: 0.8rem;
                        width: 1px;
                    }
                }
            }
        }

        &Link {
            cursor: pointer;

            @include vars.mq-medium-up {
                @include mixins.new-design-link();

                font-weight: vars.$font-weight--regular;
            }
        }
    }
}

.welcomeMessageLink {
    @include mixins.new-design-link($hasUnderline: true);
}
