@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.completedViewContainer {
    @include mixins.card();
}

.completedViewTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.completedViewButton {
    align-items: center;
    display: flex;
    font-size: 0.85rem;
    gap: 0.5rem;

    &Text {
        @include responsive.media('<=tablet') {
            @include mixins.a11y-visually-hidden();
        }
    }
}

.completedViewContent {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    gap: 1rem;
    justify-content: space-between;
}

.completedViewPrice {
    white-space: nowrap;
}

.completedViewContentTitle {
    display: block;
    font-size: 1rem;
    font-weight: fonts.$font-weight--medium;
}

.checkoutStepSubtitle {
    @include mixins.checkoutStepSubtitle();
}

.completedViewHeading {
    @include mixins.checkoutStepTitle();
}

.completedViewError {
    color: colors.$text-color--red;
}

.successIcon {
    @include mixins.success-icon();
}
