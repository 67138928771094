@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';
@use '@/styles/resources/colors';

$sidebarWidth: 16.875rem; // 270px

.sidebar {
    background: var(--color--white, colors.$color--white);
    display: grid;
    opacity: 0;
    overflow: auto;
    position: sticky;

    &::-webkit-scrollbar-track {
        background-color: colors.$color--white;
    }

    &::-webkit-scrollbar {
        background-color: colors.$color--white;
        width: 0.2rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: colors.$color--white;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: colors.$color--gray-lighter;
        }
    }

    @include responsive.media('>tablet') {
        flex: 0 0 $sidebarWidth;
        margin-left: -$sidebarWidth;
        max-height: calc(100vh - (var(--filter-bar-height, 0px) + var(--header-general-height, 0px)));
        padding: 1.5rem 0 0;
        top: calc(var(--filter-bar-height, 0px) + var(--header-general-height, 0px));
        transition:
            margin 0.2s,
            opacity 0.2s;
        width: $sidebarWidth;
    }

    @include responsive.media('<=tablet') {
        bottom: 0;
        grid-template-rows: auto 1fr;
        height: 100%;
        left: 0;
        max-width: 18.75rem;
        overflow: hidden;
        position: fixed;
        top: 0;
        transform: translate3d(-100%, 0, 0);
        transition-duration: 0.16s;
        transition-property: transform, opacity;
        transition-timing-function: ease-in-out;
        width: 100%;
        z-index: 4;
    }

    &Open {
        margin-left: 0;
        opacity: 1;

        @include responsive.media('>tablet') {
            margin-right: 1rem;
        }
    }

    &ModalOpen {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.top {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2.5rem 0.75rem 0.5rem;

    @include responsive.media('>tablet') {
        display: none;
    }
}

.topHeader {
    display: flex;
    justify-content: space-between;
}

.content {
    align-content: start;
    height: 100%;
    overflow: hidden auto;
    padding-right: 1rem;
    position: relative;
}

.contentLoader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}

.mask {
    composes: root_active from '@/components/Mask/mask.module.scss';

    @include responsive.media('>tablet') {
        display: none;
    }
}

.totalItemsLabel {
    display: flex;
    gap: 0.25rem;

    p {
        align-self: baseline;
    }
}

// Skeleton Styles
.skeletonSidebarOpen {
    composes: sidebar;
    margin: 0;
    opacity: 1;

    @include responsive.media('>tablet') {
        margin-right: 1rem;
        padding-right: 1rem;
    }
}

.skeletonAccordion {
    border-bottom: 1px solid var(--color--gray-400);
    padding: 0.75rem 0;
    width: 100%;
}

.skeletonSummary {
    padding: 0.75rem;
    width: 100%;
}

.skeletonColorsSummary {
    composes: skeletonSummary;
    padding-top: 0;
}

.skeletonFilterContent {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 1.1rem 0.75rem;
}

.skeletonFilterSizes {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(4, 1fr);
    padding: 0.75rem;
}

.skeletonFilterColors {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.25rem 0;
}

.skeletonFilterColorOption {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0;
    text-align: center;
}
