@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';
@use '@/styles/resources/responsive';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

.root {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 1px solid colors.$color--white;
    border-radius: variables.$border-radius;
    color: colors.$text-color--secondary;
    display: flex;
    font-weight: fonts.$font-weight--medium;
    height: 2.5rem;
    outline: none;
    padding: 0 3.5rem 0 2.5rem;
    position: relative;
    width: 100%;

    + label {
        display: none;
    }

    &::placeholder {
        color: colors.$text-color--secondary;
    }
}

.textInput {
    background-color: transparent;
}

.label {
    color: colors.$color--gray-dark;
    font-size: 0.875rem;
    position: absolute;
    top: 0.875rem;
    transition: all 0.2s ease-in-out;
}

@include whiteHeader.white-header-media {
            .root {
                border: 1px solid var(--color--gray-200);
                color: var(--color--gray-700);

                &::placeholder {
                    color: var(--color--gray-700);
                }
            }
}
