@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/fonts';

.tab {
    bottom: -1px;
    cursor: pointer;
    display: inline-block;
    font-weight: fonts.$font-weight--medium;
    list-style: none;
    padding: 0.25rem 1rem 1rem;
    position: relative;
    text-align: center;
    width: 50%;

    @include vars.mq-medium-up {
        min-width: 16rem;
        width: unset;
    }
}

.tabList {
    border-bottom: 1px solid var(--color--gray-500);
    color: var(--color--gray-500);
    margin-bottom: 1rem;
}

.tabSelected {
    border-bottom: 1px solid var(--color--brand-primary-500);
    color: var(--color--brand-primary-500);
}

.button {
    border-bottom: 1px solid var(--color--black);
    padding-top: 0.1rem;
}

.modalDialog {
    position: fixed;
    top: 0;
    width: 100%;

    @include vars.mq-medium-up {
        width: min(75rem, 80%);
    }
}
