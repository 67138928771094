@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'sass:color';

@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';

.productName {
    display: block;
    flex: 1;
    font-weight: fonts.$font-weight--bold;
    line-height: 1.2;
    text-transform: uppercase;
    word-break: break-word;
}

.suggestionLink {
    border: 1px solid colors.$border-color--primary;
    border-radius: variables.$border-radius;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    width: 100%;

    > img {
        margin: 0 auto;
    }

    &:hover,
    &:global(.suggestionSelected) {
        border-color: color.adjust(colors.$border-color--primary, $lightness: -5%);

        .productName {
            color: var(--brand-color--primary, colors.$brand-color--primary);
        }
    }
}

.ratingsWrapper {
    display: flex;
    gap: 0.25rem;
}

.productImage {
    align-items: center;
    display: inline-flex;
    flex: 1;
    justify-content: center;
}

.productImageThumbnail {
    left: initial;
    right: initial;
}
