/*
/* Z-index values
*/

/*
/* Header
*/

$index--header: 4; // It's also a search autocomplete reference z-index
$index--header-secondary-actions: 1;
$index--header-top-bar: $index--header;

$index--mega-menu-submenu-hidden: -2;
$index--mega-menu-submenu-visible: -1;

$index--search-autocomplete: -1;
$index--search-autocomplete-wrapper: 1;
$index--search-bar: -1;
$index--search-bar-active: 1;
$index--search-bar-container: 1;
$index--search-bar-mask: -1;
$index--search-bar-search-icon: 1;

$index--store-switcher: 1;

$index--filter-bar: 1;

/*
/* Main
*/

$index--main-content: 1;

/*
/* Modal
*/

$index--modal: $index--header;
$index--modal-content: $index--modal + 1;

/*
/* Tooltip
*/

$index--tooltip: 1;

/*
/* Overlay
*/

$index--overlay: 1;

/*
/* Text Input
*/

$index--text-input-label: -1;

/*
/* PLP Filters
*/

$index--filters: $index--header - 1;

/*
/* Product Swatches
*/

$index--active-swatch: 1;

/*
/* Product Grid
*/

$index--product-hover: 1;
$index--product-details-segment: 2;

/*
/* Cookie Consent Banner
*/

$index--cookie-consent-banner: 10;

/*
/* PDP Omnipresent Add To Cart
*/

$index--omnipresent-add-to-cart: 5;

/*
/* Confirm Customer loading screen
*/

$index--confirm-customer-bar: $index--main-content;

/*
/* Checkout
*/

$index--checkout-panel: $index--main-content;
$index--review-step: 0;
$index--payment-method-selected: 1;
$index--background-image: -1;

/*
/* Toast
*/

$index--toast-container: 6;

/*
/* Product Gallery Modal
*/

$index--product-gallery-modal-root: 15;
$index--product-gallery-modal-close-icon: 16;
