@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';

.highlightClassName {
    background-color: transparent;
    font-weight: fonts.$font-weight--bold;
}

.unHighlightClassName {
    font-weight: fonts.$font-weight--medium;
}

.suggestionLink {
    border-radius: variables.$border-radius;
    display: inline-block;
    padding: 0.2rem 0;
    text-align: left;
    width: 100%;

    &:hover,
    &:global(.suggestionSelected) {
        background-color: colors.$border-color--primary;
        color: var(--brand-color--primary, colors.$brand-color--primary);

        .highlightClassName {
            color: var(--brand-color--primary, colors.$brand-color--primary);
        }
    }
}

.itemsCount {
    margin-left: 0.5rem;

    &::before,
    &::after {
        color: colors.$text-color--primary;
    }

    &::before {
        content: '(';
        margin-right: 0.1rem;
    }

    &::after {
        content: ')';
        margin-left: 0.1rem;
    }
}
