@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/fonts';

.labelWrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 3.5rem 2fr 1fr;
}

.radioLabelText {
    font-size: 0.75rem;
}

.radioLabelTitle {
    display: block;
    font-size: 0.875rem;
    font-weight: fonts.$font-weight--medium;
}

.radioLabelIcon {
    height: 1.3rem;
    width: 3rem;
}

.radioLabelPrice {
    text-align: right;
}
