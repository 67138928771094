@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';

.customerServiceWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 1.5rem;
    position: relative;
}

.customerServiceContainer {
    display: grid;
    gap: 2rem;
    grid-template: "header" "menu" "content";
    grid-template-columns: 1fr;

    @include responsive.media('>mobile') {
        gap: 2rem 3rem;
        grid-template: "header header" "menu content";
        grid-template-columns: 1fr 4fr;
    }
}

.customerServiceSubtitle {
    display: block;
    font-size: 0.8rem;
    text-align: center;
}

.customerServiceAside {
    grid-area: menu;
}

@include responsive.media('>tablet', 'pointer-fine') {
    .customerServiceWrapper {
        gap: 2rem;
    }
}

.customerServiceLoader {
    grid-area: content;
    min-height: 50vh;

    @include responsive.media('<=tablet') {
        min-height: 80vh;
    }
}
